<!--
 * @Author: wh
 * @Date: 2020-07-22 14:49:30
 * @LastEditTime: 2022-02-23 17:40:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\components\selectUser\selectUser.vue
-->
<template>
    <div class="selectUser">
        <div class="search-con">
            <el-input v-model="search" placeholder="请输入内容" @change="searchChange"></el-input>
        </div>
        <div class="main">
            <div>
                <h4>部门：</h4>
                <div class="frame">
                    <el-tree
                        :data="treeData"
                        node-key="Dept_Id"
                        :props="defaultProps"
                        @node-click="handleNodeClick"
                    ></el-tree>
                </div>
            </div>
            <div>
                <h4>人员：</h4>
                <div class="frame">
                    <ul class="wait-users">
                        <li
                            v-for="(item,index) in waitUsers"
                            :key="`wait-users-${item.User_Id}`"
                            @click="clickWaitUsers(item,index)"
                            :class="{'active':index===activeIndex}"
                        >
                            {{ item.User_Name }}
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <h4>已选人员：</h4>
                <div class="frame">
                    <div class="selected-user" v-if="selectUser">
                        {{ selectUser.User_Name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-con">
            <el-button size="medium" @click="handleCancel">
                取消
            </el-button>
            <el-button
                size="medium"
                type="primary"
                @click="onSave"
            >
                确定
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            isCurrentDept: false,
            roleCodes: 'J01-03',
            search: '',
            treeData: [],
            defaultProps: {
                children: 'Childs',
                label: 'Dept_Name',
            },
            waitUsers: [],
            activeIndex: -1,
            selectUser: null,
            selectId: '',
            selectNode: [],
        };
    },
    watch: {},
    computed: {},
    created() {
        if (this.extr?.isCurrentDept) {
            this.isCurrentDept = this.extr.isCurrentDept;
        }
        if (this.extr?.roleCodes) {
            this.roleCodes = this.extr.roleCodes;
        }
    },
    mounted() {
        this.getDept();
    },
    methods: {
        // 保存
        onSave() {
            if (this.selectUser) {
                this.extr.sureCallBack(this.selectUser);
            }
            window.hideToast();
        },
        // 取消
        handleCancel() {
            if (this.extr?.cancelCallBack) {
                this.extr.cancelCallBack();
            }
            window.hideToast();
        },
        // 树点击事件
        handleNodeClick(data) {
            this.selectId = data.Dept_Id;
            this.waitUsers = data.Userlist;
            this.selectNode = [data];
        },
        // 待选列表点击事件
        clickWaitUsers(data, index) {
            this.activeIndex = index;
            this.selectUser = data;
        },
        // 搜索
        searchChange() {
            if (this.selectId) {
                const data = this.recursionData(this.selectNode);
                this.waitUsers = data;
            } else {
                const data = this.recursionData(this.treeData);
                this.waitUsers = data;
            }
        },
        recursionData(data) {
            const arr = [];
            data.map(item => {
                item.Userlist.map(v => {
                    if (v.User_Name.indexOf(this.search) !== -1) {
                        arr.push(v);
                    }
                });
                if (item.Childs.length > 0) {
                    this.recursionData(item.Childs);
                }
            });
            return arr;
        },
        async getDept() {
            const res = await this.getDeptApi();
            if (res) {
                this.treeData = res;
            }
        },
        // 拉取部门数据
        getDeptApi() {
            const url = `/interfaceApi/basicplatform/deptusers/deptanduser/tree?isCurrentDept=${this.isCurrentDept}&roleCodes=${this.roleCodes}`;
            return this.$axios.$get(url);
        },
    },
};
</script>
<style lang="stylus">
.selectUser
    height 100%
    padding .2rem
    .search-con
        width 100%
    .main
        height calc(100% - 1rem)
        display flex
        margin-top .1rem
        justify-content space-between
        >div
            flex 1
            height 100%
            margin-right .1rem
            &:last-child
                margin-right 0
            .frame
                border 1px solid #dedede
                height calc(100% - .4rem)
                margin-top .1rem
                overflow-y auto
                padding .2rem
                .wait-users
                    li
                        padding .1rem
                        cursor pointer
                        box-sizing border-box
                        &.active
                            background #feeabf
                            border 1px solid #fecd82
                .selected-user
                    padding .1rem
                    cursor pointer
                    box-sizing border-box
                    background #edeeef
        .el-tree-node__label
            font-size .16rem
        .el-tree-node__content
            height .32rem
    .btn-con
        position absolute
        left 0
        bottom .2rem
        width 100%
        text-align center
</style>